import { Link } from 'theme-ui'

const styles = {
  root: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  }
}

const EmailLink = ({ text }) => {
  return (
    <Link sx={styles.root} href={`mailto:${text}`} title='Email'>
      {text}
    </Link>
  )
}

export default EmailLink
