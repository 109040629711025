import React, { Fragment } from 'react'
import { Link as GLink } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { Container, Box, Flex, Link } from 'theme-ui'
import ContentIcon from '@solid-ui-components/ContentIcon'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import PhoneLink from '@solid-ui-components/PhoneLink'
import EmailLink from '@solid-ui-components/EmailLink'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `space-between`,
    alignItems: [`center`, `flex-start`],
    py: 5
  },
  specialText: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  }
}

const FooterBlock01 = ({ content: { images, collection, text } }) => {
  const { t } = useI18next()
  const buttonsColection = collection.filter(col => !!col.buttons?.length)

  return (
    <Box sx={styles.wrapper}>
      <Container px='4'>
        <Flex sx={styles.footer}>
          <Box sx={{ minWidth: 200, textAlign: ['center', 'left'] }}>
            <Box pb='1' mb='2' mt={[4, 0]}>
              <GLink to='/'>
                <ContentImages content={{ images }} imageEffect='fadeIn' />
              </GLink>
            </Box>
            <Box pt='2'>
              {t('Interdyscyplinarny Zakład Analiz Energetycznych (DIZ)')}
            </Box>
            <Box mb={[2, 4]}>{t('Narodowe Centrum Badań Jądrowych')}</Box>
            <Box pt='2' sx={{ fontSize: 13 }}>
              © 2022 IDEA
            </Box>
          </Box>
          {buttonsColection?.map(
            ({ text, buttons }, index) =>
              buttons && (
                <Box key={`item-${index}`} mb='3'>
                  <ContentText
                    content={text?.[0] || { text: '\xA0' }}
                    variant='h5'
                    sx={{ display: [`none`, `block`] }}
                  />
                  <ContentButtons
                    content={buttons}
                    variant='vertical'
                    wrapperStyles={{ flexDirection: [null, `column`] }}
                  />
                </Box>
              )
          )}

          <Box mb='3' sx={{ textAlign: ['center', 'right'], color: '#333333' }}>
            {collection?.map(({ text, icon }, index) => {
              const textContent = text?.[0]?.text
              const iconContent = icon?.[0]?.icon

              if (textContent?.replace(/ /g, '').match(/^\+\d+$/)) {
                return (
                  <Fragment key='contact-phone'>
                    <ContentIcon content={iconContent} size='sm' mr='3' />
                    <PhoneLink text={textContent} />
                  </Fragment>
                )
              } else if (
                textContent?.match(
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                )
              ) {
                return (
                  <Fragment key='contact-email'>
                    <ContentIcon content={iconContent} size='sm' mr='3' />
                    <EmailLink text={textContent} />
                  </Fragment>
                )
              }

              return (
                <Fragment key={index}>
                  <ContentIcon content={icon} size='sm' mr='3' />
                  <ContentText
                    key={`more-info-${index}`}
                    content={text}
                    wrapperStyles={{ flexDirection: [null, `column`] }}
                  />
                </Fragment>
              )
            })}
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

FooterBlock01.defaultProps = {
  menuJustify: `flex-end`
}

export default WithDefaultContent(FooterBlock01)
